
import {Component, Watch, Mixins, Prop} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import IletisimTuruPicker from "@/components/pickers/IletisimTuruPicker.vue";
import UlkeTelefon from "@/components/inputs/UlkeTelefon.vue";
import TelefonNumarasi from "@/components/inputs/TelefonNumarasi.vue";
import EPosta from "@/components/inputs/EPosta.vue";
import {UlkeEntity} from "@/entity/UlkeEntity";
import {IletisimBilgisiTuru} from "@/enum/IletisimBilgisiTuru";

@Component({
  components: {
    FormWrapper,
    IletisimTuruPicker,
    UlkeTelefon,
    TelefonNumarasi,
    EPosta
  },
})
export default class IletisimForm extends Mixins(ObjectInputMixin) {
  @Prop({}) action !: string;
  @Prop({}) successOnValidate !: string | boolean;

  IletisimTurleri = IletisimBilgisiTuru;
  ulkeler: Array<UlkeEntity> = [];

  get localAction() {
    if (this.localValue && this.localValue.id) {
      return "/api/v1/iletisim-bilgisi";
    }
    return this.action;
  }
  onClose(){
    this.$emit("onClose");

  }
  get isSuccessOnValidate() {
    return this.successOnValidate || this.successOnValidate === "";
  }

  @Watch('localValue.iletisim_tur_id')
  onLocalValueChange(ov: any, nv: any) {
    if (ov != nv) {
      delete this.localValue.deger;
      this.input();
    }
  }

  load() {
    this.$emit("load");
  }
};
