
import {Component, Prop, Watch} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import TakipList from "@/components/lists/TakipList.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {MuvekkilEntity} from "@/entity/MuvekkilEntity";
import {AlacakliEntity} from "@/entity/AlacakliEntity";

@Component({
  components: {TakipList},
})
export default class DeleteVekaletDialog extends DeleteDialog {
  @Prop() action!: string;
  @Prop({default: false}) disabled!: boolean;
  @Prop() item !: VekaletEntity;
  @Prop() disabledText !: string | null;
  show: boolean = false;
  state: string = "asking";
  takipleriGoster: boolean = false;
  vekaletinTakipleri: Array<TakipEntity> = [];
  takiplerTaken = false;
  takipVarMi = false;

  get content(): string {
    if (this.takipVarMi)
      return "Silmek istediğiniz vekalete ait takip bilgisi bulunmaktadır. Vekaleti silmek için öncelikle bulunduğu takiplerden çıkarmanız gerekmektedir. Aksi takdirde vekalet bilgileri bulunduğu takiplerden silenecektir.";
    else
      return "Seçtiğiniz kayıt kalıcı olarak silinecektir. Devam etmek istediğinize emin misiniz?";
  }

  @Watch('show')
  onShowChange() {
    if (this.show && !this.takiplerTaken)
      this.takipleriGetir();
  }

  takipleriGetir() {
    let takipIdleri: Array<number> = [];
    this.item.muvekkiller.forEach((muvekkil: MuvekkilEntity) => {
      muvekkil.takipler.forEach((muvTakip: TakipEntity) => {
        if (takipIdleri.indexOf(muvTakip.id) == -1)
          takipIdleri.push(muvTakip.id);
      });
    });
    if (takipIdleri.length > 0) {
      this.takipVarMi = true;
      this.$http.post('/api/v1/muvekkil/takip-listesi', takipIdleri).then((res) => {
        this.vekaletinTakipleri = res.data;
      });
    }
  }

  load() {
    this.$emit('load');
  }

  onSubmit() {
    if (this.action) {
      this.state = "deleting";
      this.$http.delete(this.action).then(() => {
        this.$emit("success");
        this.state = "asking";
        this.onClose();
      }).catch((err) => {
        this.state = "asking";
      });
    } else {
      this.$emit("success");
      this.onClose();
    }
    this.vekaletinTakipleri.forEach((takip: TakipEntity) => {
      takip.alacaklilar.forEach((alacakli: AlacakliEntity) => {
        if (alacakli.muvekkil.vekalet_id === this.item.id) {
          this.$http.delete("/api/v1/alacakli/" + alacakli.id)
        }
      })
    })
  }
}
