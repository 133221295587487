

import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TakipDurumu, TakipDurumuLabel} from "@/enum/TakipDurumu";

@Component({
  components: {EnumPicker}
})
export default class TakipDurumuPicker extends Mixins(SimpleInputMixin) {
  @Prop({default:'Takip Durumu'}) label !: string;

  @Prop() disabled !: boolean;
  @Prop() defaultFirst !: boolean | string;

  takipDurumu = TakipDurumu;
  takipDurumuLabel = TakipDurumuLabel

  get isDefaultFirst() {
    return this.defaultFirst || this.defaultFirst == '';
  }
}
