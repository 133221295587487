import {PersonelEntity} from "@/entity/PersonelEntity";
import {KurumEntity} from './KurumEntity';
import {SirketTipi} from "@/enum/SirketTipi";
import {AdresEntity} from "@/entity/AdresEntity";
import {IletisimBilgisiEntity} from "@/entity/IletisimBilgisiEntity";
import {BankaHesabiEntity} from "@/entity/BankaHesabiEntity";

export class BuroEntity{
    id!: number;
    isim!: string;
    vergi_no!: number | null;
    vergi_dairesi!: string | null;
    mersis_no!:number | null;
    buro_tipi!:number | null;
    personeller:Array<PersonelEntity> = [];
    email!: string;
    adres!: AdresEntity;
    iletisim_bilgileri: Array<IletisimBilgisiEntity> = [];
    banka_hesaplari: Array<BankaHesabiEntity> = [];
}
