
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {VekaletEntity} from "@/entity/VekaletEntity";
import {TableHeader} from "@/interface/TableHeader";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import VekaletForm from "@/components/forms/VekaletForm.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";
import DeleteVekaletDialog from "@/components/dialogs/DeleteVekaletDialog.vue";
import {TakipDurumu} from "@/enum/TakipDurumu";
import SayfaBilgiInfo from "@/components/SayfaBilgiInfo.vue";
import SayfaAciklama from "@/components/SayfaAciklama.vue";

@Component({
  components: {SayfaAciklama, SayfaBilgiInfo, DeleteVekaletDialog, FormDialog, DeleteDialog, VekaletForm}
})
export default class VekaletList extends Vue {

  @Prop({}) items!: Array<VekaletEntity>;
  @Prop({default: true}) vekaletEkleSil!: boolean;
  search: string = "";
  allCheckbox = false
  buroCheckbox: boolean[] = [];
  programPath = ProgramPaths;
  vekaletler: Array<VekaletEntity>  = [];
  iconName = 'mdi-trash-can-outline'
  color="#959595"
  programPaths = ProgramPaths;


  headers: TableHeader[] = [
    /*{text: '', align: 'start', sortable: false, value: 'checkbox' },*/
    {text: "Vekalet No", align: 'start', value: "vekalet_no",},
    {text: "Müvekiller", align: 'start', value: 'muvekkiller', sortable: false},
    {text: "Noter", value: "noter.isim", sortable: false},
    {text: "Vekaletname Tarihi", value: "vekalet_tarihi", sortable: false},
    {text: "İşlemler", value: "actions", width: 110, sortable: false, align: "end"}
  ];

  oncekiSayfa(){
    this.$router.push(this.programPaths.ofispro)
  }

  @Watch('allCheckbox')
  AllSelectCheckbox(){
    if (this.allCheckbox){
      this.buroCheckbox = this.vekaletler.map(item => true);
    }else{
      this.buroCheckbox = this.vekaletler.map(item => false);
    }
  }

  selectedDelete(){
    const selectedItems = this.items.filter((item, index) => {
      return this.buroCheckbox[index];
    });

    const selectedIds = selectedItems.map(item => item.id);

    this.$http.delete('/api/v1/vekalet/' ,{
      params: {
        ids: [selectedIds]
      }
    } ).then(this.load)
  }
  vekaletTakipleri(item: any): boolean {
    let flag = false
    for (let i = 0; i < item.muvekkiller.length; i++) {
      if (item.muvekkiller[i].takipler.length > 0) {
        let takipler = item.muvekkiller[i].takipler;
        for (let j = 0; j < takipler.length; j++) {
          if (takipler[j].takip_durumu === TakipDurumu.acik || takipler[j].takip_durumu === TakipDurumu.kapali) {
            flag = true
            break
          }
        }
        if (flag) {
          break
        }
      }
    }
    return flag
  }

  get siradakiVekaletNo() {
    let vekaletNo = 1;
    if (this.vekaletler && this.vekaletler.length > 0) {
      vekaletNo = this.vekaletler[this.vekaletler.length - 1].vekalet_no + 1;
    }
    return vekaletNo;
  }

  mounted() {
    this.vekaletler = this.items;
    if (!this.items) {
      this.load()
    }
  }

  load() {
    let action = "/api/v1/vekalet"
    if (this.search != "") {
      let no = parseInt(this.search);
      if (isNaN(no) || no > 999999999) {
        action = "/api/v1/vekalet/filter?keyword=" + this.search;
      } else {
        action = "/api/v1/vekalet/filter?no=" + this.search;
      }
    }
    this.$http.get(action).then(response => {
      this.vekaletler = response.data;
    });
  }

  @Watch('search')
  onSearchChange(ov: any, nv: any) {
    setTimeout(() => {
      if (ov != nv) {
        this.load();
      }
    }, 750);
  }

  detay(item: any) {
    if (item) {
      if (this.vekaletEkleSil) {
        this.$router.push("vekalet/" + item.id);
      } else {
        this.$emit('onClose');
        this.$router.push(this.programPath.ofispro + '/vekalet/' + item.id);
      }
    }
  }
}
