
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import CinsiyetPicker from "@/components/pickers/CinsiyetPicker.vue";
import EPosta from "@/components/inputs/EPosta.vue";
import VekilTipiPicker from "@/components/pickers/VekilTipiPicker.vue";
import PersonelTipiPicker from "@/components/pickers/PersonelTipiPicker.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import YabanciKimlikNumarasi from "@/components/inputs/YabanciKimlikNumarasi.vue";
import VergiNumarasi from "@/components/inputs/VergiNumarasi.vue";
import MersisNumarasi from "@/components/inputs/MersisNumarasi.vue";
import SsoUserPicker from "@/components/pickers/SsoUserPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import YargiBirimleriPicker from "@/components/pickers/YargiBirimleriPicker.vue";

@Component({
  components: {
    YargiBirimleriPicker,
    Dates,
    SsoUserPicker,
    YabanciKimlikNumarasi,
    Tutar,
    FormWrapper,
    PersonelTipiPicker,
    VekilTipiPicker,
    CinsiyetPicker,
    TcKimlikNumarasi,
    EPosta,
    VergiNumarasi,
    MersisNumarasi,
  },
})
export default class PersonelForm extends Mixins(ObjectInputMixin) {
  @Prop({}) buroTuru!: number | null;
  @Prop({}) vergiDairesi!: string | null;
  @Prop({}) vergiKimlik!: number | null;
  @Prop({}) mersisNo!: number | null;
  @Prop({}) action!: string;
  @Prop() hideButton!: any;
  @Ref("formWrapper") formWrapper!: FormWrapper;

  roleData = null;
  isNonSSOUser = false;

  get personelTuruRules(){
      if (this.localValue.role_id) {
        return true
      } else
        return 'Lütfen bu alanı doldurun.';
  }

  get notIdariYonetici(): boolean {
    if (this.localValue.role_id === 3) return true;
    else return false;
  }

  get isHideButton() {
    return this.hideButton || this.hideButton === "";
  }
  onClose() {
    this.$emit("onClose");
  }
  mounted() {
    this.localValue.vergi_no = this.vergiKimlik;
    this.localValue.vergi_dairesi = this.vergiDairesi;
    this.localValue.mersis_no = this.mersisNo;
  }

  save() {
    this.formWrapper.onFly = true;

    if (this.isNonSSOUser) {
      this.localValue.sso_user_id = null;
    }

    if (!this.localValue.sso_user_id && !this.localValue.email) {
      this.$toast.error("Lütfen bir kullanıcı seçin.");
      this.formWrapper.onFly = false;
      return;
    }

    this.$http
        .get("/api/v1/personel")
        .then((response) => {
          let users = [...response.data];

          if (this.localValue.id) {
            this.$http.put(this.action, this.localValue).then(() => {
              this.$emit("onSuccess");
            });
          } else {
            if (
                users.find(
                    (user) =>
                        user.sso_user_id == this.localValue.sso_user_id &&
                        this.localValue.sso_user_id !== null
                )
            ) {
              this.$toast.error("Kullanıcı zaten ekli.");
            } else {
              this.$http
                  .post(this.action, this.localValue)
                  .then(() => {
                    this.$toast.info(
                        "İşlemin tamamlanması için eklenen kullanıcının eposta adresine gönderilen doğrulama linkine tıklaması gerekmektedir."
                    );
                    this.$emit("onSuccess");
                  })
                  .finally(() => {
                    this.formWrapper.onFly = false;
                  });
            }
          }
        })
        .finally(() => (this.formWrapper.onFly = false));
  }

  onPersonelTipiChange() {
    delete this.localValue.vekil_tipi;
    this.input();
  }
  /*form*/
}
