export enum IletisimBilgisiTuru {
    telSabit = 1,
    telMobil,
    email,
    webSitesi,
    faks,
    sosyalMedya,
}

export enum IletisimBilgisiTuruLabel {
    "Sabit Telefon" = 1,
    "Mobil Telefon",
    "E-posta",
    "Web Sitesi",
    "Faks",
    "Sosyal Medya",
}