
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({})
export default class SsoUserPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;

  isLoading: boolean = false;
  items: Array<any> = [];
  search: string = "";

  @Watch("search")
  onSearchChange(newSearch: string, oldSearch: string) {
    if (newSearch !== oldSearch && this.isValidEmail(newSearch)) {
      this.searchItems();
    }
  }

  timeoutId = undefined;

  searchItems() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(async () => {
      this.isLoading = true;
      try {
        if (!this.search) {
          this.items = [];
        } else {
          let res = await this.$http.get(
              `${this.$store.state.ssoUri}api/search-user?email=${this.search}`
          );
          this.items = res || [];


          if (this.items.length === 0) {
            this.items.push({
              id: null,
              name: "Kayıtlı Değil",
              last_name: "",
              mail: `${this.search}`,
            });
          }
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    }, 500);
  }

  userInput() {
    let selectedItem =
        this.items.find((item) => this.getItemValue(item) == this.localValue) ||
        null;

    if (selectedItem) {

      if (selectedItem.id === null) {
        this.localValue = selectedItem.mail;
        this.$emit("ssouserid", null);
        this.$emit("email", selectedItem.mail);
        this.$emit("nonSSOUserSelected", true);
      } else {
        this.localValue = selectedItem.id;
        this.$emit("ssouserid", selectedItem.id);
        this.$emit("email", selectedItem.mail);
        this.$emit("nonSSOUserSelected", false);
      }
    } else {
      this.localValue = this.search;
      this.$emit("email", this.search);
      this.$emit("ssouserid", null);
      this.$emit("nonSSOUserSelected", true);
    }


    this.input();
  }

  getItemValue(item: any) {

    return item.id !== null ? item.id : item.mail;
  }


  isValidEmail(email: string): boolean {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
}
