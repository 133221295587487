
import {Component, Prop, Watch, Vue} from "vue-property-decorator";
import TelefonNumarasi from "@/components/inputs/TelefonNumarasi.vue";
import {UlkeEntity} from "@/entity/UlkeEntity";
import {IletisimBilgisiTuru} from "@/enum/IletisimBilgisiTuru";

@Component({
  components: {TelefonNumarasi}
})
export default class UlkeTelefon extends Vue {
  @Prop() value!: string;
  @Prop() iletisimTurId !: number;
  @Prop() required!: boolean | string;

  ulkeler: Array<UlkeEntity> = [];
  phone: string = "";
  prefix: string|null = "";
  ulkeSecildi: boolean = false;

    autocompleteText(event: KeyboardEvent) {
        if (this.ulkeSecildi) {
            event.preventDefault();
        }
    }

  get localValue() {
    return this.prefix + this.phone;
  }

  set localValue(value) {
    if (this.value) {
      let numberStartIndex = this.value.indexOf('(');
      if (numberStartIndex > 0) {
        this.prefix = this.value.substring(0, numberStartIndex);
        this.phone = this.value.substring(numberStartIndex);
      }
    }
  }

  get label() {
    if (this.iletisimTurId === IletisimBilgisiTuru.telMobil) {
      return "Mobil Telefon"
    } else if (this.iletisimTurId === IletisimBilgisiTuru.telSabit) {
      return "Sabit Telefon"
    } else if (this.iletisimTurId === IletisimBilgisiTuru.faks) {
      return "Faks"
    }
  }

  get isRequired() {
    return this.required || this.required == "";
  }

  mounted() {
    this.onValueChange();
    this.load();
  }

  @Watch("value")
  onValueChange() {
    this.localValue = this.value;
  }

  input() {
      this.ulkeSecildi = !!this.prefix;
    this.$emit("input", this.localValue);
  }

  load() {
    this.$http.get("/api/v1/ulke").then(response => {
      this.ulkeler = response.data;
      this.prefix = null
    });
  }
}
