

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";

@Component({
  components: {DatabasePicker}
})
export default class PersonelTipiPicker extends Mixins(SimpleInputMixin) {
  @Prop() label !: string;
  @Prop() returnId !: string | boolean;
  @Prop() rules: any;
  @Prop() multiple !: string | boolean;
  @Prop() placeholder !: string;

  get isReturnId() {
    return this.returnId || this.returnId === ""
  }

  get isMultiple() {
    return this.multiple || this.multiple === "";
  }

  returnObject(e: any) {
    this.$emit('return-object', e)
  }
}
