
import {Component, Prop, Vue} from "vue-property-decorator";
import BuroForm from "@/components/forms/BuroForm.vue";
import {BuroEntity} from "@/entity/BuroEntity";
import FormDialog from "@/components/dialogs/FormDialog.vue";

@Component({
  components: {FormDialog, BuroForm},
})
export default class BuroInfo extends Vue {
  @Prop() item!: BuroEntity;

  load() {
    this.$emit('load');
  }
}
