
import FormWrapper from "@/components/FormWrapper.vue";
import {Component, Mixins, Ref} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
  components: {
    FormWrapper,
  },
})
export default class TakipKopyalaForm extends Mixins(ObjectInputMixin) {

  @Ref('formWrapper') formWrapper!: FormWrapper;

  save(){
    this.formWrapper.onFly = true;
    let obj ={
      "id":this.localValue.id,
      "kopya_sayisi":this.localValue.adet
    }

    this.$http.post('/api/v1/takip-kopyala/',obj).then((response) => {
      this.$emit("onSuccess", response.data);
    }).finally(() => (this.formWrapper.onFly = false));
  }

}
