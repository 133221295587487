
import {Component, Mixins, Ref} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";

@Component({
  components: {
    FormWrapper,
  },
})
export default class AlacakKopyalaForm extends Mixins(ObjectInputMixin) {
  @Ref('formWrapper') formWrapper!: FormWrapper;

  save() {
    this.formWrapper.onFly = true;
    this.$http.post(`/api/v1/alacak/${this.localValue.id}/ay-ekle?ay_sayisi=${this.localValue.adet}`).then((response) => {
      this.$emit("onSuccess", response.data);
    }).finally(() => (this.formWrapper.onFly = false));
  }
}
