

import {Component, Prop, Vue } from "vue-property-decorator";
import {PersonelEntity} from "@/entity/PersonelEntity";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import PersonelForm from "@/components/forms/PersonelForm.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import {ProgramPaths} from "@/enum/ProgramPaths";
import DeletePersonelDialog from "@/components/dialogs/DeletePersonelDialog.vue";


@Component({
  components: {DeletePersonelDialog, FormDialog, PersonelForm, DeleteDialog}
})
export default class BuroPersonelList extends Vue {
  @Prop({}) buroTuru!: number | null;
  @Prop({}) vergiDairesi!: string | null;
  @Prop({}) vergiKimlik!: number | null;
  @Prop({}) mersisNo!: number | null;
  @Prop({}) action!: any;
  @Prop({}) items!: Array<PersonelEntity>;

  headers = [
    {text: "Adı", align: "start", sortable: false, value: "ad"},
    {text: "Personel Türü", value: "roles.0.name"},
    {text: "TC Kimlik No", value: "turkish_identification_number"},
    {text: "İşlemler", value: "actions", sortable: false, align: 'end', width: 100}
  ];

  get getVergiKimlik() {
    let vergiKimlik = this.vergiKimlik;
    return vergiKimlik;
  }

  get getVergiDairesi() {
    let vergiDairesi = this.vergiDairesi;
    return vergiDairesi;
  }

  get getMersisNo() {
    let mersisNo = this.mersisNo;
    return mersisNo;
  }

  load() {
    this.$emit("load");
  }

  detay(item: PersonelEntity) {
    this.$router.push(ProgramPaths.ofispro + '/personel/' + item.id?.toString());
  }
}
