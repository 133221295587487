
import {Component, Mixins, Prop} from "vue-property-decorator";
import PickerInputMixin from "@/mixins/PickerInputMixin.vue";
import {TakipTipiEntity} from "@/entity/TakipTipiEntity";

@Component({})
export default class TakipTipiPicker extends Mixins(PickerInputMixin) {
  @Prop() propItems !: Array<TakipTipiEntity>;
  @Prop() rules!: string;
  @Prop() disabled!: string | boolean;
  @Prop() clearable!: string | boolean;
  @Prop({default:'Takip Tipi'}) label !: string;
  @Prop() placeHolder !: string;


  get isDisabled() {
    return this.disabled || this.disabled === "";
  }

  get isClearable() {
    return this.clearable || this.clearable === "";
  }

  mounted() {
    this.load();
  }

  load() {
    if (this.propItems) {
      this.items = this.propItems;
    } else {
      this.$http
          .get("/api/v1/takip-tipi/")
          .then((response) => (this.items = response.data));

    }
  }
}
