
import {Component, Mixins, Prop} from "vue-property-decorator";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {DatabasePicker},
})
export default class YargiBirimleriPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId!: boolean;
  @Prop() label!: string;
  @Prop({}) ilam!: any;
  @Prop({}) rules!: string;

  get action(): string {
    return "/api/v1/yargi-birimi/filter?ilamTuru=" + this.ilam;
  }

  returnObject(e: any) {
    this.$emit('return-object', e)
  }
}
