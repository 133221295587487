
import {Component, Mixins, Prop} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  directives: {
    mask
  }
})
export default class KararNumarasi extends Mixins(SimpleInputMixin) {
  @Prop() label !: string;
  @Prop() rules !: string;
  @Prop({default:'Giriniz'}) placeHolder !: string;


  @Prop({default:"####/#######"}) maskStr:any;
  @Prop({default:12}) maxLength!: number

  get kararNoRules(){
    if(this.localValue){
      let rule : any = [];
      if(this.localValue.length < 6){
        rule = ["Minimum 5 karakter"];
      }
      return rule;
    }
  }

  validate() {
    this.$emit("value", this.localValue);
  }
};
