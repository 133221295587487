

import {Component, Mixins, Prop} from "vue-property-decorator";
import EnumPicker from "@/components/pickers/EnumPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {VekilTipi} from "@/enum/VekilTipi";

@Component({
  components: {EnumPicker}
})
export default class VekilTipiPicker extends Mixins(SimpleInputMixin) {
  @Prop() rules!: string;
  @Prop() label!: string;

  vekilTipi = VekilTipi;
}
