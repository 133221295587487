

import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";

@Component({
  components: {DatabasePicker}
})
export default class IletisimTuruPicker extends Mixins(SimpleInputMixin) {
  @Prop() returnId !: number;
}
